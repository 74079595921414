// import { useState } from "react";
import { useState, useEffect } from "react";
import { Menu, Button, Image } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/logojoyliday.jpg";
import axios from "axios";
import { axios_json } from "../../axios";
import responseHandleSrv from "../../services/responseHandleSrv";
import { authHeader } from "../../services/auth-header";
import {
  FormOutlined,
  CheckSquareOutlined,
  SmileOutlined,
  ShoppingOutlined,
  TeamOutlined,
  LineChartOutlined,
  UserOutlined,
  ControlOutlined,
  BranchesOutlined,
  SoundOutlined,
  BarChartOutlined,
  ExportOutlined,
  HomeOutlined
} from "@ant-design/icons";

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const [menuList, setMenuList] = useState([]);
  // const [roleMenu, setRoleMenu] = useState([]);
  let menu_list =
    localStorage.getItem("menu_list") === null
      ? ""
      : JSON.parse(localStorage.menu_list);
  let roleMenu =
    localStorage.getItem("role_menu") === null
      ? ""
      : JSON.parse(localStorage.role_menu);
  // if (roleMenu == "") {
  //   localStorage.role_menu = JSON.stringify([
  //     1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
  //   ]);
  // }
  // const [editorState, setEditorState] = useState(EditorState.createEmpty());
  useEffect(() => {
    if (menu_list == "") {
      (async () => {
        try {
          const headers = authHeader();
          // const uri = `${process.env.REACT_APP_API_UAT}/api/menu`;
          const res = await axios_json.get(`/api/menu`, { headers });

          if (res.data.success) {
            localStorage.menu_list = JSON.stringify(res.data.data);
            setMenuList(res.data.data);
          }
        } catch (Error) {
          // setEditorState(EditorState.createEmpty());
        } finally {
          // setPreload(false);
        }
      })();
    } else {
      setMenuList(menu_list);
    }
  }, []);

  // console.log('menuList', menuList);
  // console.log('role_menu_list', roleMenu);
  const iconsMenu = {
    "Products": <ShoppingOutlined />,
    "Customer": <TeamOutlined />,
    "Consent": <CheckSquareOutlined />,
    "Policy": <FormOutlined />,
    "Report": <BarChartOutlined />,
    "Users": <UserOutlined />,
    "Roles": <ControlOutlined />,
    "Categories": <BranchesOutlined />,
    "Promotions": <SoundOutlined />,
    "Redeem" : <LineChartOutlined />,
    "E-stamp" : <FormOutlined />,
    "Joyli coin" : <FormOutlined />,
    "Campaign" : <FormOutlined />,
    "Segmentation" : <FormOutlined />,
    "Line Broadcast" : <FormOutlined />,
    "Line Dashboard": <FormOutlined />,
    "Branchs": <HomeOutlined />,
  };
  let menuList_items = [];

  const menuList_obj = {};
  if (menuList.length > 0) {
    menuList.forEach((menu) => {
      menuList_obj[menu.id] = menu;
    });
  }

  console.log("menuList_obj", menuList_obj);
  if (menuList.length > 0 && roleMenu.length > 0) {
    // console.log('roleMenu', roleMenu);
    roleMenu.forEach((value) => {
      let obj = menuList_obj[value];
      obj.icon = iconsMenu[obj.name];
      menuList_items.push(obj);
    });
  }

  menuList_items = menuList_items.sort((a, b) => a.sort - b.sort);

  // console.log("menuList_items", menuList_items);

  const parentItems = menuList_items.filter((menuItems) => {
    return menuItems.parent === 0;
  });
  parentItems.sort((a, b) => a.sort - b.sort);
  console.log('parentItems',parentItems)

  return (
    <>
      <div className={"brand"} >
        <span class="icon">
          <Image src={logo} preview={false} />
        </span>
        <span>Joyliday Admin</span>
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        {parentItems &&
          parentItems.map((p) => (
            <>
              <Menu.Item className="menu-item-header" key={`P${p.id}`}>
                {p.name}
              </Menu.Item>

              {menuList_items
                .filter((c) => c.parent === p.id)
                .map((m) => (
                  <>
                    <Menu.Item key={m.id}>
                      <NavLink to={`/${m.route}`}>
                        <span
                          className="icon"
                          style={{
                            background: page === m.route ? color : "",
                          }}
                        >
                          {m.icon}
                        </span>
                        <span className="label">{m.name}</span>
                      </NavLink>
                    </Menu.Item>
                  </>
                ))}
            </>
          ))}
        {/* Link test */}
        {/* <Menu.Item key={999}>
          <NavLink to={`/promotions`}>
            <span className="label">Promotions</span>
          </NavLink>
        </Menu.Item> */}
      </Menu>
    </>
  );
}

export default Sidenav;
